import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import { DO_NOT_RENDER } from '../../helpers/constants';
import { slideData } from '../full-width-image-carousel/dataMappers';
import type { FullWidthDynamicHeroProps } from './full-width-dynamic-hero';

export const mapHomePageDataToFullWidthDynamicHero = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<FullWidthDynamicHeroProps> => {
  const imageSlidesData = slideData(homepageData?.images?.fullWidthImageCarousel);
  const videosWithoutSources = homepageData?.videos.filter((video) => !video.source);

  if (videosWithoutSources?.length) {
    const selectedVideo = videosWithoutSources[0];
    const captionTracks = selectedVideo.metadata.filter((data) => data.type === 'captionTrack');

    return {
      video: {
        isAutoPlay: !!selectedVideo.autoPlay,
        videoUrl: selectedVideo.url,
        orientation: selectedVideo?.orientation ?? 'landscape',
        posterImageUrl: selectedVideo?.posterImageUrl ?? '',
        captionTracks,
      },
    };
  }

  if (imageSlidesData) {
    return {
      carousel: {
        imageSlidesData,
      },
    };
  }

  return DO_NOT_RENDER;
};
