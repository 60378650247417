import { getServerSideClients } from '@dx-ui/framework-react-query';
import { getQueryProviderProps } from '@dx-ui/framework-react-query-env-vars';
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { CustomHead } from '../../../components/head';
import type { StandardSchema } from '../../../components/head/page-schemas';
import HotelLayout from '../../../components/layout/HotelLayout';
import { serverSideGetHotelHomePageQuery } from '../../../generated/react-query';
import { GetBrandSvg } from '../../../helpers/assets';
import {
  mapHomePageDataToComponentProps,
  renderComponents,
} from '../../../helpers/componentMapper';
import { GLOBAL_NAMESPACES, HotelPage } from '../../../helpers/constants';
import { getLayoutData } from '../../../helpers/layout';
import { getCtyhocnFromSlug, getHotelsRouteParams } from '../../../helpers/routing';
import {
  getDehydratedQueryState,
  serverSideLayoutAndRedirects,
} from '../../../helpers/serverSideHelpers';
import { FeatureToggles, isFeatureToggleEnabled } from '../../../helpers/toggles';
import { useStaticUrls } from '../../../hooks/useStaticUrls';
import { registration } from '../../../mocks/UtilityRailData.mock';
import nextI18nextConfig from '../../../next-i18next.config';

import { shouldReturnQueryDataFromGraphQLError } from '../../../helpers/property-utils';
import { getDefaultBrandTheme } from '../../../helpers/themes/defaultTheme';
import type { GetHotelHomePageQuery } from '../../../generated/types';

const pageName = HotelPage.HOME;
const namespaces = [
  ...GLOBAL_NAMESPACES,
  'close-button',
  'dining',
  'dx-image-carousel',
  'honors',
  'hotel-description',
  'hotel-location',
  'rooms',
  'vouchers',
  'gallery-grid',
  'dx-dialog-with-content',
  pageName,
];

export default function Page({
  componentsToRender,
  layout,
  pageSchema,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const router = useRouter();
  const { ctyhocn } = getHotelsRouteParams(router);
  const { baseAppUrl } = useStaticUrls();
  const standardSchema: StandardSchema = {
    ...pageSchema,
    url: baseAppUrl,
  };

  return (
    <HotelLayout layout={layout} page={pageName} ctyhocn={ctyhocn}>
      <CustomHead
        brandLogoImageSrc={GetBrandSvg(layout?.headerData.brandCode)}
        schema={standardSchema}
        metaDescription={layout?.seoMetaData.desc}
        metaKeywords={layout?.seoMetaData.keywords}
        page={pageName}
        pageTitle={layout?.seoMetaData.title}
        brandCode={layout?.headerData.brandCode}
        shouldUseDefaultBrandFavicon={layout?.headerData?.suppressLogo}
      />
      {...renderComponents(componentsToRender)}
    </HotelLayout>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { res, params, locale } = context;
  const envVars = getQueryProviderProps();
  const ctyhocn = getCtyhocnFromSlug(params?.hotelSlug);
  const { queryClient } = getServerSideClients({
    customHeaders: { 'dx-trusted-app': 'true' },
    ...envVars,
    appName: 'dx-property-ui',
    response: res,
  });

  const {
    componentOrder,
    globalConfig,
    layoutResult,
    mainNavLinks,
    resEnabled,
    notFound,
    oneLinkProps,
    originalLocale,
    featureToggles,
  } = await serverSideLayoutAndRedirects({
    queryClient,
    ctyhocn,
    pageName,
    hotelSlug: params?.hotelSlug as string,
    context,
  }).catch((e) => {
    console.log('Error in serverSideLayoutAndRedirects', e); // eslint-disable-line no-console

    return {
      componentOrder: null,
      globalConfig: null,
      layoutResult: null,
      mainNavLinks: null,
      resEnabled: null,
      notFound: true,
      oneLinkProps: null,
      originalLocale: null,
      featureToggles: null,
      featureConfigs: null,
    };
  });

  if (notFound) {
    return { notFound: true };
  }

  const homeResult: GetHotelHomePageQuery = await serverSideGetHotelHomePageQuery(queryClient, {
    ctyhocn,
  }).catch((e) => {
    console.log('Error in serverSideGetHotelHomePageQuery', e); // eslint-disable-line no-console
    if (shouldReturnQueryDataFromGraphQLError(e)) {
      return e.data;
    }

    return null;
  });

  if (!homeResult) {
    return { notFound: true };
  }

  const layout = getLayoutData({
    layoutResult,
    resEnabled,
    mainNavLinks,
    locale: originalLocale || locale,
  });

  const brandCode = layoutResult?.hotelPageTemplate?.hotel?.brandCode || '';

  const pageSchema: Omit<StandardSchema, 'url'> = {
    address: layout?.address,
    amenityFeature: homeResult?.hotel?.amenities?.map((amenity) => amenity.name),
    checkinTime: registration.checkinTimeFmt,
    checkoutTime: registration.checkoutTimeFmt,
    coordinates: layout?.coordinates,
    image: layout?.image || '',
    openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su',
    schemaType: 'Hotel',
    description: layout?.seoMetaData.desc,
    name: layout?.hotelName,
    telephone: layout?.phone || '',
  };

  const isStayTourVideoEnabled = isFeatureToggleEnabled({
    featureToggleName: FeatureToggles.OHWPR_3402_Stay_Tour_Video,
    data: featureToggles,
  });

  const isEmbassyRefreshToggleEnabled = isFeatureToggleEnabled({
    featureToggleName: FeatureToggles.NHCBP_5435_Embassy_Refresh,
    data: featureToggles,
  });

  const componentsToRender = mapHomePageDataToComponentProps(componentOrder ?? [], {
    ctyhocn,
    homepageData: homeResult.hotel,
    isStayTourVideoEnabled,
    layout,
    showMeetingsSimplified: !!layoutResult?.hotelPageTemplate?.hotel?.showMeetingsSimplified,
  });

  return {
    props: {
      componentsToRender,
      dehydratedQueryState: getDehydratedQueryState(queryClient),
      globalConfig,
      layout,
      isEmbassyRefreshToggleEnabled,
      themeColors: layout.theme ?? getDefaultBrandTheme(brandCode),
      pageSchema,
      propCode: layoutResult?.hotelPageTemplate?.hotel?.propCode,
      ...(await serverSideTranslations('en', namespaces, nextI18nextConfig)),
      ...oneLinkProps,
      metrics: {
        trackingData: {
          pageData: {
            brandCode,
            brandName: layout?.headerData?.brandName,
            destinationUrl: layout?.homeUrlPath,
            lang: locale || 'en',
            pageName,
            ctyhocn,
            template: globalConfig?.template,
            country: layout?.country || '',
          },
        },
      },
    },
  };
}
